import "@fontsource/shippori-mincho/400.css"
import "@fontsource/shippori-mincho/500.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/500.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/bitter/400.css"
import "@fontsource/bitter/500.css"
import "@fontsource/bitter/700.css"
import "@fontsource/playfair-display/500.css"
//////////
// 参考 : https://github.com/gatsbyjs/gatsby/issues/13667
/////////

//node js の syntax
// ここですべてのページに共通してLayoutComponentを配置することができる。
// すべてのページの要素がElementに自動的に格納されている。
// props に引数が格納されている。

import React from "react"
import Layout from "./src/components/layout"

import { ThemeProvider } from "./src/context/ThemeContext"

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider>
      <Layout {...props}>{element}</Layout>
    </ThemeProvider>
  )
}
