// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ambientmix-js": () => import("./../../../src/pages/ambientmix.js" /* webpackChunkName: "component---src-pages-ambientmix-js" */),
  "component---src-pages-artwork-js": () => import("./../../../src/pages/artwork.js" /* webpackChunkName: "component---src-pages-artwork-js" */),
  "component---src-pages-autumn-js": () => import("./../../../src/pages/autumn.js" /* webpackChunkName: "component---src-pages-autumn-js" */),
  "component---src-pages-ballcosmos-js": () => import("./../../../src/pages/ballcosmos.js" /* webpackChunkName: "component---src-pages-ballcosmos-js" */),
  "component---src-pages-beecolony-js": () => import("./../../../src/pages/beecolony.js" /* webpackChunkName: "component---src-pages-beecolony-js" */),
  "component---src-pages-cakes-js": () => import("./../../../src/pages/cakes.js" /* webpackChunkName: "component---src-pages-cakes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cssankey-js": () => import("./../../../src/pages/cssankey.js" /* webpackChunkName: "component---src-pages-cssankey-js" */),
  "component---src-pages-dancingtiles-js": () => import("./../../../src/pages/dancingtiles.js" /* webpackChunkName: "component---src-pages-dancingtiles-js" */),
  "component---src-pages-dataviz-js": () => import("./../../../src/pages/dataviz.js" /* webpackChunkName: "component---src-pages-dataviz-js" */),
  "component---src-pages-generativecity-js": () => import("./../../../src/pages/generativecity.js" /* webpackChunkName: "component---src-pages-generativecity-js" */),
  "component---src-pages-hannya-js": () => import("./../../../src/pages/hannya.js" /* webpackChunkName: "component---src-pages-hannya-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layercones-js": () => import("./../../../src/pages/layercones.js" /* webpackChunkName: "component---src-pages-layercones-js" */),
  "component---src-pages-movingblob-js": () => import("./../../../src/pages/movingblob.js" /* webpackChunkName: "component---src-pages-movingblob-js" */),
  "component---src-pages-polygonlayer-js": () => import("./../../../src/pages/polygonlayer.js" /* webpackChunkName: "component---src-pages-polygonlayer-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-projects-contentful-project-title-js": () => import("./../../../src/pages/projects/{ContentfulProject.title}.js" /* webpackChunkName: "component---src-pages-projects-contentful-project-title-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-ray-js": () => import("./../../../src/pages/ray.js" /* webpackChunkName: "component---src-pages-ray-js" */),
  "component---src-pages-wavesquare-js": () => import("./../../../src/pages/wavesquare.js" /* webpackChunkName: "component---src-pages-wavesquare-js" */),
  "component---src-pages-zennetwork-js": () => import("./../../../src/pages/zennetwork.js" /* webpackChunkName: "component---src-pages-zennetwork-js" */)
}

