const data = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: "cv",
    text: "CV",
    url:
      // "mainImg.png"
      "https://docs.google.com/document/d/1IY2M4g9s8rKNsQGdVPDCz7WIgZp3ovzLINLrYqLT3IA/edit?usp=sharing",
  },
  // {
  //   id: 3,
  //   text: "project",
  //   url: "/project/",
  // },
  {
    id: 4,
    text: "publication",
    url: "/publication/",
  },
  {
    id: 5,
    text: "project",
    url: "/project/",
  },
  {
    id: 6,
    text: "artwork",
    url: "/artwork/",
  },
  {
    id: 7,
    text: "contact",
    url: "/contact/",
  },
]

export default data
