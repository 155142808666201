import React from "react"
import { FaAlignRight } from "react-icons/fa"
import { AiOutlineAlignLeft } from "react-icons/ai"

import pageLinks from "../constants/links" // import する名前は links.js に記載の内容と一致しなくてもいい。
import { Link } from "gatsby"
import ThemeContext from "../context/ThemeContext"

const Navbar = ({ toggleSidebar }) => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <>
          <nav className="navbar">
            <div className="nav-center">
              <div className="nav-header">
                <button
                  type="button"
                  className="toggle-btn"
                  onClick={toggleSidebar}
                >
                  <AiOutlineAlignLeft />
                </button>
                {/* <div class="langButtons langButtonsHidden">
                  <div class="langButtonLeft">
                    <button onClick={() => theme.changeLanguage("EN")}>
                      English
                    </button>
                  </div>
                  <div class="langButtonRight">
                    <button onClick={() => theme.changeLanguage("JP")}>
                      日本語
                    </button>
                  </div>
                </div> */}
              </div>
              <div className="nav-link-holder">
                <div className="nav-links">
                  {pageLinks.map(link => {
                    return (
                      <Link key={link.id} to={link.url} className="linkText">
                        {link.text}
                      </Link>
                    )
                  })}
                  {/* <div class="langButtons">
                    <div class="langButtonLeft">
                      <button onClick={() => theme.changeLanguage("EN")}>
                        English
                      </button>
                    </div>
                    <div class="langButtonRight">
                      <button onClick={() => theme.changeLanguage("JP")}>
                        日本語
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </nav>
        </>
      )}
    </ThemeContext.Consumer>
  )
}

export default Navbar
