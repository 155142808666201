import React from "react"
const defaultState = {
  language: "EN",
  dark: false,
  toggleDark: () => {},
  changeLanguage: () => {},
}
// const defaultState = {
// }
const ThemeContext = React.createContext(defaultState)
// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
// const supportsDarkMode = () =>
//   window.matchMedia("(prefers-color-scheme: dark)").matches === true
class ThemeProvider extends React.Component {
  state = {
    dark: false,
    language: "EN",
  }
  toggleDark = target => {
    let dark = !this.state.dark
    // localStorage.setItem("dark", JSON.stringify(dark))
    this.setState({ dark })
  }
  changeLanguage = target => {
    // localStorage.setItem("dark", JSON.stringify(dark))
    this.setState({ language: target })
  }
  componentDidMount() {
    this.setState({ dark: true })
    this.setState({ language: "EN" })
  }

  //   componentDidMount() {
  //     // Getting dark mode value from localStorage!
  //     const lsDark = JSON.parse(localStorage.getItem("dark"))
  //     if (lsDark) {
  //       this.setState({ dark: lsDark })
  //     }
  //     // } else if (supportsDarkMode()) {
  //     //   this.setState({ dark: true })
  //     // }
  //   }
  render() {
    const { children } = this.props
    const { language } = this.state
    const { dark } = this.state
    return (
      <ThemeContext.Provider
        value={{
          language,
          dark,
          toggleDark: this.toggleDark,
          changeLanguage: this.changeLanguage,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}
export default ThemeContext
export { ThemeProvider }
