import React from "react"
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaMedium,
  FaGithubSquare,
  FaFlickr,
  FaTwitterSquare,
} from "react-icons/fa"
import { SiGooglescholar } from "react-icons/si"

const data = [
  {
    id: 1,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/kentaro-takahira-33a9ba117/",
  },

  {
    id: 6,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    url: "https://twitter.com/KentaroTakahira",
  },

  {
    id: 5,
    icon: <FaInstagramSquare className="social-icon"></FaInstagramSquare>,
    url: "https://www.instagram.com/cocomils11/",
  },
  {
    id: 2,
    icon: <FaGithubSquare className="social-icon"></FaGithubSquare>,
    url: "https://github.com/cocomil11",
  },

  {
    id: 9,
    icon: <SiGooglescholar className="social-icon"></SiGooglescholar>,
    url: "https://scholar.google.com/citations?hl=en&user=CoPUduUAAAAJ",
  },
]

export default data
